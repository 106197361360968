import React from "react";
import Helmet from "react-helmet";
import { Flex, Box } from "@rebass/grid";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Container from "../components/Container";
import H1 from "../components/H1";
import H2 from "../components/H2";
import H3 from "../components/H3";
import Lead from "../components/Lead";
import Button from "../components/Button";
import styled from "styled-components";

import AppStoreBadge from "../img/AppStoreBadge.svg";

import { BrowserView, AndroidView, IOSView } from "react-device-detect";

const BlackH2 = styled(H2)`
  color: #000000;
`;

class AppDownloadRoute extends React.Component {
  render() {
    return (
      <Layout>
        <Container>
          <Helmet title={`Download de app | ${this.props.title}`}>
            <meta
              name="description"
              content="Download de Appmantle app op jouw iOS of Android telefoon."
            />
          </Helmet>
          <BrowserView>
            <Box my={6}>
              <H1>Download de Appmantle App</H1>
              <Lead>
                Open deze pagina (
                <a href="appmantle.com/app">appmantle.com/app</a>) op jouw
                telefoon om onze app te installeren.
              </Lead>
              {typeof window !== "undefined" && navigator.share && (
                <Button
                  withArrow
                  onClick={() => {
                    navigator.share({
                      title: "Download de Appmantle App",
                      url: "https://appmantle.com/app"
                    });
                  }}
                >
                  Stuur deze link naar jezelf
                </Button>
              )}
            </Box>
          </BrowserView>
          <AndroidView>
            <Box my={6} style={{ textAlign: "center" }}>
              <a href="https://play.google.com/store/apps/details?id=com.appmantle.preview&pcampaignid=Website-Download">
                <BlackH2>Download de Appmantle App voor Android</BlackH2>
                <img
                  height="80px"
                  alt="Ontdek het op Google Play"
                  src="https://play.google.com/intl/en_us/badges/images/generic/nl_badge_web_generic.png"
                />
              </a>
            </Box>
          </AndroidView>
          <IOSView>
            <Box my={5}>
              <H3>Stap 1:</H3>
              <br />
              <a
                href="https://itunes.apple.com/us/app/testflight/id899247664?mt=8"
                target="_blank"
              >
                <BlackH2>Download TestFlight</BlackH2>
                <Box my={3}>
                  <img
                    height="50px"
                    alt="Download in de App Store"
                    src={AppStoreBadge}
                  />
                </Box>
              </a>
            </Box>
            <Box my={5}>
              <H3>Stap 2:</H3>
              <br />
              <H2>Open Appmantle in TestFlight</H2>
              <Box my={3}>
                <Button
                  as="a"
                  href="itms-beta://testflight.apple.com/join/TpMMpf7w"
                  large
                  primary
                >
                  Open Appmantle in TestFlight
                </Button>
              </Box>
            </Box>
          </IOSView>
        </Container>
      </Layout>
    );
  }
}

export default AppDownloadRoute;

export const tagPageQuery = graphql`
  query AppDownloadPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
